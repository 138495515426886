<template>
	<div class="min-h-screen bg-black flex">
		<div class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
			<div class="mx-auto w-full max-w-sm lg:w-96 -mt-32">
				<div>
					<img alt="Conduction PTY LTD" class="h-32 w-auto mx-auto mb-24 mt-" src="/logo.svg" />
					<h2 class="mt-6 text-3xl font-extrabold text-white">Login to your account</h2>
				</div>

				<div class="mt-6">
					<div class="mt-6 relative">
						<div aria-hidden="true" class="absolute inset-0 flex items-center">
							<div class="w-full border-t border-gray-300" />
						</div>
						<div class="relative flex justify-center text-sm">
							<span class="px-2 bg-white text-gray-500"> </span>
						</div>
					</div>

					<div class="mt-6">
						<FormKit
							id="loginForm"
							v-model="formData"
							type="form"
							class="flex w-full"
							:sections-schema="{
								messages: {
									attrs: {
										class: { 'w-full': true, flex: true, 'items-center': true, 'justify-center': true, 'my-2': true },
									},
								},
							}"
							submit-label="Login"
							:submit-attrs="{
								'sections-schema': {
									prefix: loadingElement('loginForm'),
								},
								outerClass: {
									'col-span-12': true,
									'sm:col-span-12': true,
									'lg:col-span-12': true,
									'xl:col-span-12': true,
								},
								inputClass:
									'$reset w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500',
							}"
							@submit="login"
						>
							<div class="grid grid-cols-12 gap-x-2 gap-y-2 sm:gap-y-4 my-4">
								<FormKitSchema :schema="loginSchema" />
							</div>
						</FormKit>
						<div class="flex items-center justify-center pt-2">
							<div class="text-sm">
								<span class="text-white">First time here? </span>
								<router-link class="font-medium text-primary-400 hover:text-primary-500" to="/register">Register a new account!</router-link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="hidden lg:block relative w-0 flex-1">
			<img alt="" class="absolute inset-0 h-full w-full object-cover" src="https://images.unsplash.com/photo-1529412983309-f09e81bbcdc0?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1908&q=80" />
		</div>
	</div>
</template>

<script setup lang="ts">
	import { ref } from 'vue';
	import { useAuthStore } from '@modules/auth/store';
	import { useRouter } from 'vue-router';
	import { useUserStore } from '@modules/user/store';
	import { loadingElement } from '@modules/form/utils/elements';
	import { FormKitNode } from '@formkit/core';

	const authStore = useAuthStore();
	const router = useRouter();

	//Send to dashboard if they are already authenticated
	if (authStore.isAuthenticated) {
		router.push({
			name: 'dashboard',
		});
	}

	type LoginFormData = {
		email: string;
		password: string;
	};

	const formData = ref<LoginFormData>({
		email: '',
		password: '',
	});

	const login = async (data: LoginFormData, node: FormKitNode) => {
		return new Promise((r) => {
			authStore
				.login(data.email, data.password)
				.then((response) => {
					if (!response) {
						node.setErrors(['Sorry! It looks like we are having some problems!']);
					} else if ('requires_two_factor' in response) {
						router.push({
							name: 'challenge',
						});
					} else if ('requires_device_authorization' in response) {
						// do device authorization stuff
					} else {
						const userStore = useUserStore();
						userStore.getLoggedInUser();
						router.push({
							name: 'dashboard',
						});
					}
				})
				.catch((error) => {
					if (error.message == 'Failed to fetch') {
						node.setErrors(['Sorry! It looks like we are having some problems!']);
					} else {
						node.setErrors([error.message]);
					}
					r('');
				});
		});
	};

	const loginSchema = [
		{
			$formkit: 'email',
			name: 'email',
			label: 'Email address',
			labelClass: {
				$reset: true,
				block: true,
				'text-sm': true,
				'font-medium': true,
				'text-white': true,
				'mb-1': true,
			},
			validation: [['required'], ['email']],
			innerClass: '$reset mt-1 formkit-invalid:border-red-500 focus-within:border-primary-500',
			inputClass: '$reset appearance-none block w-full px-3 py-2 border-0 rounded-md placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm',
			outerClass: {
				'col-span-12': true,
				'sm:col-span-12': true,
				'lg:col-span-12': true,
				'xl:col-span-12': true,
			},
		},
		{
			$formkit: 'password',
			name: 'password',
			label: 'Password',
			validation: [['required']],
			labelClass: {
				$reset: true,
				block: true,
				'text-sm': true,
				'font-medium': true,
				'text-white': true,
			},
			innerClass: '$reset mt-1 formkit-invalid:border-red-500 focus-within:border-primary-500',
			inputClass: '$reset appearance-none block w-full px-3 py-2 border-0 rounded-md placeholder-gray-400 focus:outline-none focus:ring-primary-500 focus:border-primary-500 sm:text-sm',
			outerClass: {
				'col-span-12': true,
				'sm:col-span-12': true,
				'lg:col-span-12': true,
				'xl:col-span-12': true,
			},
		},
		{
			$el: 'div',
			attrs: {
				class: { 'w-full': true, flex: true, 'items-center': true, 'justify-center': true, 'text-sm': true, 'col-span-12': true, 'sm:col-span-12': true, 'lg:col-span-12': true, 'xl:col-span-12': true, 'my-2': true },
			},
			children: [
				{
					$el: 'a',
					attrs: {
						href: '/forgot',
						class: { 'font-medium': true, 'text-primary-400': true, 'hover:text-primary-500': true },
					},
					children: 'Forgot your password?',
				},
			],
		},
	];
</script>
